import dotProp from 'dot-prop-immutable';

import {
    REDUCE_ENTITY,
    REDUCE_SUBSCRIPTION,
    REDUCE_SUBSCRIPTION_ELEMENT,
    REDUCE_SUBSCRIPTIONS
} from '../../constants/actions/subscription';

const initialState = {
    entity: null,
    clientSubscriptions: []
};

function subscriptionReducer(state = initialState, action) {
    let elementIndex = 0;
    switch (action.type) {
        case REDUCE_ENTITY:
            return dotProp.set(state, `entity`, action.payload);
        case REDUCE_SUBSCRIPTION:
            elementIndex = state.clientSubscriptions.findIndex(x => x.guid === action.payload.guid);
            return dotProp.set(state, `clientSubscriptions.${elementIndex}`, action.payload);
        case REDUCE_SUBSCRIPTION_ELEMENT:
            elementIndex = state.clientSubscriptions.findIndex(x => x.guid === action.payload.guid);
            return dotProp.set(state, `clientSubscriptions.${elementIndex}.${action.payload.code}`, action.payload.value);
        case REDUCE_SUBSCRIPTIONS:
            return dotProp.set(state, 'clientSubscriptions', action.payload);
        default:
            return state;
    }
}

export default subscriptionReducer;
