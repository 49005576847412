import dotProp from 'dot-prop-immutable';

import {
    REDUCE_BROKERCOMPANY_PARTNER_REFERENCE,
    REDUCE_BROKERCOMPANY_ELEMENT,
    REDUCE_BROKERCOMPANY,
    REDUCE_BROKER_COMPANY_QUERY_ELEMENT,
    REDUCE_BROKERCOMPANIES,
    REDUCE_ENTITY,
    REDUCE_BROKERCOMPANY_FEE,
    REDUCE_BROKERCOMPANY_PRODUCT,
    REDUCE_FILE_UPLOADS_HISTORY
} from '../../constants/actions/brokerCompany';

const initialState = {
    entity: null,
    brokerCompany: null,
    query: {
        take: 25,
        skip: 0,
        orderByDesc: true
    },
    brokerCompanies: [],
    fileUploadsHistory: [],
    billingDashboard: null
};

function brokerCompanyReducer(state = initialState, action) {
    let elementIndex = 0;
    switch (action.type) {
        case REDUCE_ENTITY:
            return dotProp.set(state, 'entity', action.payload);
        case REDUCE_BROKERCOMPANY_ELEMENT:
            if (!state.brokerCompany) {
                state = dotProp.set(state, 'brokerCompany', {});
            }
            return dotProp.set(state, `brokerCompany.${action.payload.code}`, action.payload.value);
        case REDUCE_BROKERCOMPANY_PARTNER_REFERENCE:
            elementIndex = state.brokerCompany.brokerCompanyExternalReferences.findIndex(ele => ele.brokerCompanyExternalReferenceGuid === action.payload.value.brokerCompanyExternalReferenceGuid) 
            if(elementIndex >= 0){
                return dotProp.set(state, `brokerCompany.brokerCompanyExternalReferences.${elementIndex}`, action.payload.value);
            } else {
                let lengthOfReference = state.brokerCompany.brokerCompanyExternalReferences?.length
                return dotProp.set(state, `brokerCompany.brokerCompanyExternalReferences.${lengthOfReference ? lengthOfReference : `0`}`, action.payload.value);
            }
        case REDUCE_BROKERCOMPANY_FEE:
            elementIndex = state.brokerCompany.brokerCompanyFees.findIndex(ele => ele.brokerCompanyFeeGuid === action.payload.value.brokerCompanyFeeGuid)
            if (elementIndex >= 0) {
                return dotProp.set(state, `brokerCompany.brokerCompanyFees.${elementIndex}`, action.payload.value);
            } else {
                let lengthOfReference = state.brokerCompany.brokerCompanyFees?.length
                return dotProp.set(state, `brokerCompany.brokerCompanyFees.${lengthOfReference ? lengthOfReference : `0`}`, action.payload.value);
            }

        case REDUCE_BROKERCOMPANY_PRODUCT:
            elementIndex = state.brokerCompany.brokerCompanyProducts.findIndex(ele => ele.brokerCompanyProductGuid === action.payload.value.brokerCompanyProductGuid)
            if (elementIndex >= 0) {
                return dotProp.set(state, `brokerCompany.brokerCompanyProducts.${elementIndex}`, action.payload.value);
            } else {
                let lengthOfReference = state.brokerCompany.brokerCompanyProducts?.length
                return dotProp.set(state, `brokerCompany.brokerCompanyProducts.${lengthOfReference ? lengthOfReference : `0`}`, action.payload.value);
            }

        case REDUCE_BROKERCOMPANY:
            return {
                ...state,
                brokerCompany: {
                    ...state.brokerCompany,
                    ...action.payload
                }
            };
        case REDUCE_BROKER_COMPANY_QUERY_ELEMENT:
            return dotProp.set(state, `query.${action.payload.code}`, action.payload.value);
        case REDUCE_BROKERCOMPANIES:
            return dotProp.set(state, 'brokerCompanies', action.payload);
        case REDUCE_FILE_UPLOADS_HISTORY:
            return dotProp.set(state, 'fileUploadsHistory', action.payload);
        case 'REDUCE_BILLING_DASHBOARD':
            return dotProp.set(state, 'billingDashboard', action.payload);
        default:
            return state;
    }
}

export default brokerCompanyReducer;
